/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import { message } from 'antd';
import { GO_TO_LOGIN, NO_MESSAGE } from './constants';

export interface YWRequestConfig extends AxiosRequestConfig {
  loginHost?: string;
}

export interface YWResponse extends AxiosResponse {
  config: YWRequestConfig;
}

const instance = axios.create({
  timeout: 30 * 1000,
});

instance.interceptors.request.use(
  (config) => {
    const configData = config;
    if (typeof window !== 'undefined') {
      const token = localStorage.getItem('token') || '';
      configData.headers = {
        ...configData.headers,
        'X-TOKEN': token,
      };
    }
    return configData;
  },
  (error) => Promise.reject(error),
);

instance.interceptors.response.use(
  (res: YWResponse) => {
    const { data, config } = res;
    const { success, code } = data;
    if (success) {
      return data.data || success;
    }
    if (GO_TO_LOGIN.indexOf(Number(code)) >= 0 && typeof window !== 'undefined') {
      const { loginHost = '' } = config;
      window.top.location.href = `${loginHost}/login?redirectUrl=${encodeURIComponent(window.location.href)}`;
    }
    if (typeof window !== 'undefined' && NO_MESSAGE.indexOf(code) < 0) {
      message.error(data.msg || data.message);
    }

    return Promise.reject(data);
  },
  (error) => {
    if (typeof window !== 'undefined') {
      message.error('网络繁忙, 休息一会儿再来吧~ ^_^！');
    }

    return Promise.reject(error);
  },
);

export default class Request {
  static get<T>(url: string, config?: YWRequestConfig): Promise<T> {
    return instance.get(url, config);
  }

  static post<T>(
    url: string,
    data?: any,
    config?: YWRequestConfig,
  ): Promise<T> {
    return instance.post(url, data, config);
  }
}
