import { ClientParams } from './useClientData.interface';
import { FolderItem, FileItem, RecordProps } from './useFileData.interface';

export interface AliyunInfoAction {
  type: string;
  payload?: {
    data: AliyunInfoState;
  };
}

export enum AliyunInfoActionType {
  SET_ROOT_FOLDER = 'setRootFolder',
  GET_FILE_LIST = 'getFileList',
  JUMP = 'jump',
  SET_LOADING = 'setLoading',
  SET_RECORD = 'setRecord',
  START_PUT = 'startPut',
  SET_CHECKED = 'setChecked',
  SET_DOWNLOAD_LOADING = 'setDownloadLoading',
  SET_FAIL_LIST = 'setFailList',
  INIT_CLIENT = 'initClient',
}

export interface AliyunInfoState {
  client?: ClientParams;
  /* 根目录 */
  rootFolder: string;
  /* 面包屑 */
  breadcrumbList: string[];
  /* 分页 */
  nextMarker?: string;
  /* 分页 */
  listLoading: boolean;
  /* 文件夹列表 */
  folderList: FolderItem[];
  /* 文件列表 */
  fileList: FileItem[];
  /* 更新记录 */
  updateRecord: RecordProps;
  /* 多文件上传 */
  putFileLoading: boolean;
  /* 文件下载 */
  downloadLoading: string;
  /*  */
  checked: {
    /* 是否全选 */
    allChecked: boolean;
    /* 文件夹列表 */
    folderList: string[];
    /* 文件列表 */
    fileList: string[];
  };
  failModal: {
    visible: boolean,
    list: string[],
  };
}
