/** 本文件由工具自动生成,请勿手动修改！ **/
// @ts-nocheck
import { useState, useEffect } from 'react';
import * as R from 'ramda';
import * as querystring from 'querystring';
import { ParsedUrlQueryInput } from 'querystring';
import Request, { YWRequestConfig } from '@ywfe/request';
import { getPath, reduceParams } from './utils';

const { basePath, loginPath } = getPath('COMMON');

export interface APIOssInfoReq {}

export interface APIOssInfoRes {
  accessKeyId: string;
  accessKeySecret: string;
  expiration: string;
  requestId: string;
  securityToken: string;
}

// 获取oss账号

export const useGetAPIOssInfo = () => {
  let isMount = true;
  const [isAPIOssInfoLoading, setIsAPIOssInfoLoading] = useState(false);
  const [dataAPIOssInfo, setDataAPIOssInfo] = useState<APIOssInfoRes>(
    {} as APIOssInfoRes,
  );
  const fetchAPIOssInfoData = async (config?: YWRequestConfig): Promise<APIOssInfoRes> => {
    if (isMount) {
      setIsAPIOssInfoLoading(true);
    }

    try {
      const res: any = await Request.get<APIOssInfoRes>(`${basePath}/oss/sts/getInfo`, {
        loginHost: loginPath,
        ...config,
      });

      if (isMount) {
        setDataAPIOssInfo(res || ({} as APIOssInfoRes));
        setIsAPIOssInfoLoading(false);
      }
      return res;
    } catch (error) {
      console.error('API error: ', error);
      if (isMount) {
        setIsAPIOssInfoLoading(false);
      }
      return false as unknown as APIOssInfoRes;
    }
  };

  useEffect(() => {
    return () => {
      isMount = false;
    };
  }, []);

  return {
    isAPIOssInfoLoading,
    dataAPIOssInfo,
    fetchAPIOssInfoData,
  };
};
