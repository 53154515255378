import { createSubscriptionHook } from '@ywfe/hook-subscription';
import { AliyunInfoState, AliyunInfoAction, AliyunInfoActionType } from './index.interface';

const initialState: AliyunInfoState = {
  rootFolder: '/',
  breadcrumbList: [],
  listLoading: true,
  fileList: [],
  folderList: [],
  updateRecord: {},
  putFileLoading: false,
  downloadLoading: '',
  checked: {
    allChecked: false,
    fileList: [],
    folderList: [],
  },
  failModal: {
    visible: false,
    list: [],
  },
};

const reducer = (state = initialState, action: AliyunInfoAction) => {
  const { type, payload } = action;
  switch (type) {
    // 初始化获取client
    case AliyunInfoActionType.INIT_CLIENT: {
      return {
        ...state,
        client: payload.data.client,
      };
    }
    // 修改根文件夹
    case AliyunInfoActionType.SET_ROOT_FOLDER: {
      return {
        ...state,
        rootFolder: payload.data.rootFolder,
      };
    }
    // 获取文件列表
    case AliyunInfoActionType.GET_FILE_LIST: {
      return {
        ...state,
        nextMarker: payload.data.nextMarker,
        listLoading: payload.data.listLoading,
        fileList: payload.data.fileList,
        folderList: payload.data.folderList,
      };
    }
    // 文件夹跳转
    case AliyunInfoActionType.JUMP: {
      return {
        ...state,
        nextMarker: payload.data.nextMarker,
        listLoading: payload.data.listLoading,
        breadcrumbList: payload.data.breadcrumbList,
        fileList: payload.data.fileList,
        folderList: payload.data.folderList,
        checked: payload.data.checked,
      };
    }
    // 获取文件列表
    case AliyunInfoActionType.SET_LOADING: {
      return {
        ...state,
        listLoading: payload.data.listLoading,
        updateRecord: payload.data.updateRecord || state.updateRecord,
        failModal: payload.data.failModal || state.failModal,
      };
    }
    // 重置文件更新记录
    case AliyunInfoActionType.SET_RECORD: {
      return { ...state, updateRecord: payload.data.updateRecord };
    }
    // 重置文件更新记录
    case AliyunInfoActionType.START_PUT: {
      return { ...state, putFileLoading: payload.data.putFileLoading };
    }
    // 选择设置
    case AliyunInfoActionType.SET_CHECKED: {
      return {
        ...state,
        checked: payload.data.checked,
        downloadLoading: typeof payload.data.downloadLoading !== 'undefined' ? payload.data.downloadLoading : state.downloadLoading,
      };
    }
    // 选择下载Loading
    case AliyunInfoActionType.SET_DOWNLOAD_LOADING: {
      return {
        ...state,
        downloadLoading: payload.data.downloadLoading,
      };
    }
    // 获取删除失败文件列表
    case AliyunInfoActionType.SET_FAIL_LIST: {
      return {
        ...state,
        listLoading: payload.data.listLoading,
        failModal: payload.data.failModal,
        checked: payload.data.checked,
      };
    }
    default:
      return state;
  }
};

export const useAliyunInfoStore = createSubscriptionHook(reducer, initialState);
