/* eslint-disable no-await-in-loop */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useRef } from 'react';
import { message } from 'antd';
import moment from 'moment';
import FileSaver from 'file-saver';// https://stuk.github.io/jszip/
import JSZip from 'jszip';// https://github.com/eligrey/FileSaver.js/
import { getFileName, getFileType } from '@utils/file';
import { BufferBigEndian } from '@utils/bufferBigEndian';
import { useAliyunInfoStore } from './index.store';
import { AliyunInfoActionType } from './index.interface';
import { FolderItem, FileItem, GetFileListProps, PutFileProps, RecordProps } from './useFileData.interface';

// 上传，下载时的进度条
// 看看有啥方法能生成预览链接

export const systemUploadName = '__system_upload.json';

export const useFileData = () => {
  const [state, dispatch] = useAliyunInfoStore();
  const userName = localStorage.getItem('userName');
  const buf = new BufferBigEndian();
  const fileDataRef = useRef({
    putFileNums: 0,
    putFileRecords: {},
    putFileBreadcrumb: '',
    startDownload: false,
  });

  // 获取当前路径
  const getPath = (breadcrumbList?: string[]) => {
    const list = breadcrumbList || state.breadcrumbList;
    const rootFolder = state.rootFolder === '/' ? '' : `${state.rootFolder}/`;
    return list.length
      ? `${rootFolder}${list.join('/')}`
      : `${rootFolder}`;
  };
  // 获取记录
  const getNewRecord = (obj?: any) => ({
    createName: userName,
    createTime: moment().valueOf(),
    createTimeStr: moment().format('YYYY-MM-DD HH:mm'),
    updateName: userName,
    updateTime: moment().valueOf(),
    updateTimeStr: moment().format('YYYY-MM-DD HH:mm'),
    ...(obj || {}),
  });
  // 删除文件
  const deleteFile = async (name) => {
    try {
      await state.client.delete(name);
      await updateJson(undefined, name.split('/').pop());
      jump('refresh');
    } catch (err) {
      message.error('文件删除失败');
      window.console.log(err);
    }
  };
  // 多个文件删除
  const deleteMulti = async (folder?: string, files?: string[]) => {
    let { fileList, folderList } = state.checked;

    if (folder) {
      fileList = [];
      folderList = [folder];
    } else if (files) {
      fileList = files;
      folderList = [];
    }

    const updateJsonList = fileList.map((item) => item.split('/').pop());
    try {
      // 填写需要删除的多个Object完整路径并设置返回模式为简单模式。Object完整路径中不能包含Bucket名称。
      dispatch({
        type: AliyunInfoActionType.SET_LOADING,
        payload: {
          data: {
            ...state,
            listLoading: true,
            checked: folder ? state.checked : {
              allChecked: false,
              fileList: [],
              folderList: [],
            },
            failModal: {
              visible: false,
              list: [],
            },
          },
        },
      });
      for (let i = 0; i < folderList.length; i += 1) {
        const folder = folderList[i];
        updateJsonList.push(`__folder_${folder.split('/').filter((e) => e).pop()}`);
        const list = await state.client.list({
          'max-keys': 1000,
          prefix: folder,
        });
        fileList.unshift(...list.objects.map((item) => item.name));
      }
      const result = await state.client.deleteMulti(fileList, { quiet: true });
      await updateJson(undefined, updateJsonList);
      if (result.deleted.length) {
        dispatch({
          type: AliyunInfoActionType.SET_FAIL_LIST,
          payload: {
            data: {
              ...state,
              listLoading: false,
              failModal: {
                visible: true,
                list: result.deleted,
              },
            },
          },
        });
      } else {
        dispatch({
          type: AliyunInfoActionType.SET_LOADING,
          payload: {
            data: {
              ...state,
              listLoading: false,
            },
          },
        });
        jump('refresh');
      }
    } catch (e) {
      dispatch({
        type: AliyunInfoActionType.SET_LOADING,
        payload: {
          data: {
            ...state,
            listLoading: false,
          },
        },
      });
      message.error('文件批量删除失败');
      window.console.log(e);
    }
  };
  // 关闭弹框
  const closeFailModal = () => {
    dispatch({
      type: AliyunInfoActionType.SET_FAIL_LIST,
      payload: {
        data: {
          ...state,
          listLoading: false,
          failModal: {
            visible: false,
            list: [],
          },
          checked: {
            allChecked: false,
            fileList: [],
            folderList: [],
          },
        },
      },
    });
  };
  // 层级跳转
  const jump = (type: 'add' | 'jump' | 'refresh', folderItem?: FolderItem, index?: number) => {
    let breadcrumbList = [];
    let prefix = '';
    switch (type) {
      case 'add':
        breadcrumbList = [...state.breadcrumbList, folderItem.lastName];
        prefix = folderItem.name;
        break;
      case 'jump':
        breadcrumbList = state.breadcrumbList.slice(0, index + 1);
        prefix = `${getPath(breadcrumbList)}/`;
        break;
      case 'refresh':
        breadcrumbList = state.breadcrumbList;
        prefix = `${getPath(breadcrumbList)}/`;
        break;
      default:
        break;
    }
    prefix = prefix === '/' ? '' : prefix;
    dispatch({
      type: AliyunInfoActionType.JUMP,
      payload: {
        data: {
          ...state,
          nextMarker: undefined,
          listLoading: true,
          breadcrumbList,
          folderList: [],
          fileList: [],
          checked: {
            allChecked: false,
            fileList: [],
            folderList: [],
          },
        },
      },
    });
    getFileList({ prefix, isUpdate: true }).catch(() => {});
  };
  // 获取文件列表
  const getFileList = async (params?: GetFileListProps) => {
    const { prefix, isGroup = true, isUpdate } = params || {};
    // 检测当前文件夹下是否存在更新记录保存文件
    const lastPrefix = !prefix ? getPath([]) : prefix;
    const path = `${lastPrefix}${systemUploadName}`;
    const checkResult = await isExistObject(path);
    if (checkResult === 1) {
      // 文件不存在,创建一个
      await createFolder('', path, false);
    } else if (checkResult === 2) {
      // 接口报错
      return;
    }
    // 获取更新记录
    const record = await getFileContent(path, false);
    // 文件存在获取文件列表
    dispatch({
      type: AliyunInfoActionType.SET_LOADING,
      payload: {
        data: {
          ...state,
          listLoading: true,
          updateRecord: record,
        },
      },
    });
    const fileList: FileItem[] = [];
    const folderList: FolderItem[] = [];

    const result = await state.client.list({
      marker: isUpdate ? undefined : state.nextMarker,
      'max-keys': 1000,
      prefix: lastPrefix,
      delimiter: isGroup ? '/' : undefined,
    });

    let index = 0;
    fileList.push(...((result.objects || []).filter((item) => item.name !== prefix)).map((item) => {
      const fileType = getFileType(item.name);
      const newItem: FileItem = {
        ...item,
        fileType,
        lastName: getFileName(item.name),
      };
      if (fileType === 'image') {
        newItem.imageIndex = index;
        index += 1;
      }
      return newItem;
    }));

    folderList.push(...(result.prefixes || []).map((name: string) => ({
      name,
      lastName: getFileName(name),
    })));

    dispatch({
      type: AliyunInfoActionType.GET_FILE_LIST,
      payload: {
        data: {
          ...state,
          nextMarker: result.nextMarker,
          listLoading: false,
          folderList,
          fileList,
        },
      },
    });
  };
  // 上级文件更新
  const updatePreJson = async () => {
    if (state.breadcrumbList.length > 0) {
      // 更新上一级
      const breadcrumbList = [...state.breadcrumbList];
      const lastName = breadcrumbList.pop();
      const prePath = `${getPath(breadcrumbList)}/${systemUploadName}`;
      let preRecord = await getFileContent(prePath);
      preRecord = {
        ...preRecord,
        [`__folder_${lastName}`]: {
          ...(preRecord[`__folder_${lastName}`] || {}),
          updateName: userName,
          updateTime: moment().valueOf(),
          updateTimeStr: moment().format('YYYY-MM-DD HH:mm'),
        },
      };
      const content = new Blob([JSON.stringify(preRecord, null, 2)], { type: 'application/json' });
      await state.client.put(prePath, content);
    }
  };
  // 文件更新
  const updateJson = async (newRecord?: RecordProps, fileName?: string | string[]) => {
    await updatePreJson();
    const path = `${getPath()}/${systemUploadName}`;
    let record = await getFileContent(path);
    if (typeof fileName === 'string') {
      if (!record[fileName]) return;
      delete record[fileName];
    } else if (typeof fileName === 'object') {
      fileName.forEach((name) => {
        delete record[name];
      });
    } else {
      record = {
        ...record,
        ...(newRecord || fileDataRef.current.putFileRecords),
      };
    }
    dispatch({
      type: AliyunInfoActionType.SET_RECORD,
      payload: {
        data: {
          ...state,
          updateRecord: record,
        },
      },
    });
    const content = new Blob([JSON.stringify(record, null, 2)], { type: 'application/json' });
    await state.client.put(path, content);
  };
  // 开始上传
  const startPutFile = (length: number) => {
    fileDataRef.current.putFileNums = length;
    fileDataRef.current.putFileBreadcrumb = getPath();
    fileDataRef.current.putFileRecords = {};
    dispatch({
      type: AliyunInfoActionType.START_PUT,
      payload: {
        data: {
          ...state,
          putFileLoading: true,
        },
      },
    });
  };
  // 文件上传
  const putFile = async ({ fileName, content, isRefresh = true }: PutFileProps): Promise<void> => {
    try {
      const path = getPath();
      await state.client.put(`${path}/${fileName}`, content);
      fileDataRef.current.putFileNums -= 1;
      fileDataRef.current.putFileRecords = {
        ...fileDataRef.current.putFileRecords,
        [fileName]: getNewRecord(),
      };
      const isLast = fileDataRef.current.putFileNums === 0;
      dispatch({
        type: AliyunInfoActionType.START_PUT,
        payload: {
          data: {
            ...state,
            putFileLoading: !isLast,
          },
        },
      });
      if (isLast) {
        // 更新记录
        await updateJson();
        // 刷新列表
        if (isRefresh && path === fileDataRef.current.putFileBreadcrumb) {
          jump('refresh');
        }
      }
    } catch (e) {
      message.error('文件上传失败');
      window.console.log(e);
    }
  };
  // 文件是否存在
  const isExistObject = async (url: string) => {
    try {
      await state.client.head(url);
      return 0;
    } catch (error) {
      // @ts-ignore
      if (error.code === 'NoSuchKey') {
        return 1;
      }
      message.error('网络异常，请稍后再试');
      return 2;
    }
  };
  // 获取文件内容
  const getFileContent = async (url: string, isRequest = true) => {
    const isExist = isRequest ? await isExistObject(url) : false;
    if (!isExist) {
      const result = await state.client.get(url);
      buf.pushUint8List(result.content);
      return JSON.parse(buf.getStringWithUtf8(result.content.length));
    }
    return undefined;
  };
  // 创建文件夹
  const createFolder = async (name: string, path?: string, isRefresh = true) => {
    const content = new Blob([JSON.stringify({}, null, 2)], { type: 'application/json' });
    let fileName = getPath();
    if (path) {
      fileName = path;
    } else {
      // 更新当前文件夹的更新记录文件
      await updateJson({
        [`__folder_${name}`]: getNewRecord(),
      });
      // 补全地址
      fileName = `${fileName}/${name}/${systemUploadName}`;
    }
    await state.client.put(fileName, content);
    if (isRefresh) {
      jump('refresh');
    }
  };
  /** 文件下载 */
  const downloadFile = async (file?: FileItem, folder?: FolderItem) => {
    if (fileDataRef.current.startDownload) return;
    fileDataRef.current.startDownload = true;
    let { fileList, folderList } = state.checked;
    dispatch({
      type: AliyunInfoActionType.SET_CHECKED,
      payload: {
        data: {
          ...state,
          downloadLoading: file?.lastName || folder?.lastName || 'mergingDownloadButton',
          checked: (file || folder) ? state.checked : {
            allChecked: false,
            fileList: [],
            folderList: [],
          },
        },
      },
    });
    const zip = new JSZip();
    try {
      if (file) {
        const result = await state.client.get(file.name);
        const blob = new Blob([result.content]);
        FileSaver.saveAs(blob, file.lastName); // 利用file-saver保存文件
        fileDataRef.current.startDownload = false;
        dispatch({
          type: AliyunInfoActionType.SET_DOWNLOAD_LOADING,
          payload: {
            data: {
              ...state,
              downloadLoading: '',
            },
          },
        });
      } else {
        if (folder) {
          fileList = [];
          folderList = [folder.name];
        }
        if (fileList.length === 0 && folderList.length === 0) {
          message.error('请至少选择一个文件下载');
          fileDataRef.current.startDownload = false;
          dispatch({
            type: AliyunInfoActionType.SET_DOWNLOAD_LOADING,
            payload: {
              data: {
                ...state,
                downloadLoading: '',
              },
            },
          });
          return;
        }

        for (let i = 0; i < folderList.length; i += 1) {
          const folder = folderList[i];
          const list = await state.client.list({
            'max-keys': 1000,
            prefix: folder,
          });
          for (let j = 0; j < list.objects.length; j += 1) {
            const item = list.objects[j];
            if (item.name.indexOf(systemUploadName) === -1) {
              const result = await state.client.get(item.name);
              zip.file(`${getFileName(folder)}/${item.name.replace(folder, '')}`, result.content, { binary: true }); // 逐个添加文件
            }
          }
        }

        for (let i = 0; i < fileList.length; i += 1) {
          const item = fileList[i];
          const result = await state.client.get(item);
          zip.file(getFileName(item), result.content, { binary: true }); // 逐个添加文件
        }

        zip.generateAsync({ type: 'blob' }).then((content) => { // 生成二进制流
          FileSaver.saveAs(content, `遥望云盘_${moment().valueOf()}.zip`); // 利用file-saver保存文件
        }).catch(() => {});
        fileDataRef.current.startDownload = false;
        dispatch({
          type: AliyunInfoActionType.SET_DOWNLOAD_LOADING,
          payload: {
            data: {
              ...state,
              downloadLoading: '',
            },
          },
        });
      }
    } catch (e) {
      window.console.log(e);
      message.error('文件下载失败');
      fileDataRef.current.startDownload = false;
      dispatch({
        type: AliyunInfoActionType.SET_DOWNLOAD_LOADING,
        payload: {
          data: {
            ...state,
            downloadLoading: '',
          },
        },
      });
    }
  };

  return {
    path: getPath(),
    failModal: state.failModal,
    updateRecord: state.updateRecord,
    breadcrumbList: state.breadcrumbList,
    listLoading: state.listLoading,
    folderList: state.folderList,
    fileList: state.fileList,
    putFileLoading: state.putFileLoading,
    downloadLoading: state.downloadLoading,
    checked: state.checked,
    jump,
    putFile,
    deleteFile,
    deleteMulti,
    closeFailModal,
    startPutFile,
    getFileList,
    downloadFile,
    createFolder,
    isExistObject,
    getFileContent,
  };
};
