export const configs: Record<string, any> = {
  PROAUTH: {
    mock: {
      API_BASE_URL: 'http://yapi.ywfe.com/mock',
      APPLICATION_URL: '',
    },
    dev: {
      API_BASE_URL: 'https://dev-gateway.ywwl.com',
      APPLICATION_URL: 'https://yun-dev.ywwl.com',
    },
    test: {
      API_BASE_URL: 'https://test-gateway.ywwl.com',
      APPLICATION_URL: 'https://yun-test.ywwl.com',
    },
    test2: {
      API_BASE_URL: 'https://test2-gateway.ywwl.com',
      APPLICATION_URL: 'https://yun-test2.ywwl.com',
    },
    pre: {
      API_BASE_URL: 'https://test-gateway.ywwl.com',
      APPLICATION_URL: 'https://yun-test.ywwl.com',
    },
    prod: {
      API_BASE_URL: 'https://gateway.ywwl.com',
      APPLICATION_URL: 'https://yun.ywwl.com',
    },
  },
  COMMON: {
    mock: {
      API_BASE_URL: 'http://yapi.ywfe.com/mock',
      APPLICATION_URL: '',
    },
    dev: {
      API_BASE_URL: 'https://dev-generic-api.ywwl.com',
      APPLICATION_URL: '',
    },
    pre: {
      API_BASE_URL: 'https://dev-generic-api.ywwl.com',
      APPLICATION_URL: 'http://pl.fed.ywwl.com',
    },
    test: {
      API_BASE_URL: 'https://test-generic-api.ywwl.com',
      APPLICATION_URL: 'http://framework.test.ywwl.com',
    },
    test2: {
      API_BASE_URL: 'https://test2-generic-api.ywwl.com',
      APPLICATION_URL: 'http://framework.test.ywwl.com',
    },
    prod: {
      API_BASE_URL: 'https://generic-api.ywwl.com',
      APPLICATION_URL: 'https://framework.ywwl.com',
    },
  },
  AUTH: {
    mock: {
      APPLICATION_URL: 'https://yun-dev.ywwl.com',
    },
    dev: {
      APPLICATION_URL: 'https://yun-dev.ywwl.com',
    },
    test: {
      APPLICATION_URL: 'https://yun-test.ywwl.com',
    },
    pre: {
      APPLICATION_URL: 'https://yun-test.ywwl.com',
    },
    prod: {
      APPLICATION_URL: 'https://yun.ywwl.com',
    },
  },
};
