import React from 'react';
import ReactDOM from 'react-dom';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
import App from './App';
import './styles/common.less';
import { getQuery } from './utils/common';

moment.locale('zh-cn');

const root = document.getElementById('app');

const token = getQuery('token') || '';
if (token) {
  localStorage.setItem('token', token);
}
ReactDOM.render(
  <ConfigProvider locale={zhCN}>
    <App />
  </ConfigProvider>,
  root,
);
