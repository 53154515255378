import styled from 'styled-components';

export const ModalWrapStyle = styled.div`
  .ant-breadcrumb {
    padding: 10px 20px;
    background: #fff;
    & > span:last-child {
      color: rgba(0, 0, 0, 0.5);
    }
  }
`;
