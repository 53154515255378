import React from 'react';
import { Spin } from 'antd';
import { LoadingStyle } from './styles';

const Loading = (
  <LoadingStyle>
    <Spin />
  </LoadingStyle>
);

export default Loading;
