import { useEffect } from 'react';
import { useGetAPI25815 } from '@generated/api';
import { useRootFolderData, useFileData, useClientData } from '@stores/aliyun';
import { MenuInfo } from './interface';

export const useModel = () => {
  const { rootFolder, setRootFolder } = useRootFolderData();
  const { client, getClientInfo } = useClientData();
  const { getFileList } = useFileData();
  const { fetchAPI25815Data } = useGetAPI25815();

  const handleClick = (e: MenuInfo) => {
    setRootFolder(e.key);
  };
  // 获取用户信息
  const getUserInfo = async () => {
    const res = await fetchAPI25815Data();
    if (!res) return;
    localStorage.setItem('userName', res?.userName);
    getClientInfo().catch(() => {});
  };

  useEffect(() => {
    getUserInfo().catch(() => {});
  }, []);

  useEffect(() => {
    if (client) {
      getFileList().catch(() => {});
    }
  }, [client, rootFolder]);

  return {
    rootFolder,
    handleClick,
  };
};
