/** 本文件由工具自动生成,请勿手动修改！ **/
// @ts-nocheck
import { useState, useEffect } from 'react';
import * as R from 'ramda';
import * as querystring from 'querystring';
import { ParsedUrlQueryInput } from 'querystring';
import Request, { YWRequestConfig } from '@ywfe/request';
import { getPath, reduceParams } from './utils';

const { basePath, loginPath } = getPath('COMMON');

export interface API18828Req {
  /** 项目id */
  projectId: string;
  /** 上传路径 */
  dir: string;
  /** 回调url */
  callbackUrl?: string;
  /** 安全类型，public 或  private */
  securityType: string;
}

export interface API18828Res {
  /**   */
  accessId?: string;
  /** 回调地址  */
  callback?: string;
  /** 文件上传目录  */
  dir?: string;
  /** 有效期，默认30秒  */
  expire?: string;
  /** 上传地址  */
  host?: string;
  /** 上传策略  */
  policy?: string;
  /** 签名  */
  signature?: string;
}

// 18828 获取oss签名信息

export const useGetAPI18828 = () => {
  let isMount = true;
  const [isAPI18828Loading, setIsAPI18828Loading] = useState(false);
  const [dataAPI18828, setDataAPI18828] = useState<API18828Res>(
    {} as API18828Res,
  );
  const fetchAPI18828Data = async (
    inputs: API18828Req,
    config?: YWRequestConfig,
  ): Promise<API18828Res> => {
    const params = (reduceParams(
      inputs as unknown as R.Dictionary<API18828Req>,
    ) || {}) as API18828Req;

    if (isMount) {
      setIsAPI18828Loading(true);
    }

    try {
      const res: any = await Request.get<API18828Res>(
        `${basePath}/oss/getSignatureInfo?${querystring.stringify(
          params as unknown as ParsedUrlQueryInput,
        )}`,
        {
          loginHost: loginPath,
          ...config,
        },
      );

      if (isMount) {
        setDataAPI18828(res || ({} as API18828Res));
        setIsAPI18828Loading(false);
      }
      return res;
    } catch (error) {
      console.error('API error: ', error);
      if (isMount) {
        setIsAPI18828Loading(false);
      }
      return false as unknown as API18828Res;
    }
  };

  useEffect(() => {
    return () => {
      isMount = false;
    };
  }, []);

  return {
    isAPI18828Loading,
    dataAPI18828,
    fetchAPI18828Data,
  };
};
