// @ts-ignore
import * as R from 'ramda';
import { configs } from './config';

export const getPath = (projectId: string) => {
  let env;
  if (typeof window === 'undefined') {
    env = (process.env.REACT_APP_ENV === 'prod' ? 'prod' : process.env.REACT_APP_ENV) || 'prod';
  } else {
    env = window.localStorage.getItem('env') || (process.env.REACT_APP_ENV === 'prod' ? 'prod' : process.env.REACT_APP_ENV) || 'prod';
  }

  const basePath = configs[projectId] ? configs[projectId][env].API_BASE_URL + `${env === 'mock' ? '/' + 85 : ''}` : '';
  const loginPath = configs['AUTH'] ? configs['AUTH'][env].APPLICATION_URL : '';
  return {
    basePath,
    loginPath,
  };
};

export const reduceParams = (data: any) => {
  if (!data) {
    return {};
  }
  if (data instanceof FormData) {
    return data;
  }
  const result = R.filter((item) => !R.isNil(item) && !R.isEmpty(item))(data) || {};
  return result as any;
};

export const parseParams = <T>(params: T) => {
  return R.values<any, any>(params).join('/');
};
