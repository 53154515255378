import React from 'react';
import { Menu } from 'antd';
import { YIcon } from '@ywfe/ywd';
import { useModel } from './useModel';

const Sidebar: React.FC = () => {
  const { rootFolder, handleClick } = useModel();

  return (
    <Menu onClick={handleClick} style={{ width: 256 }} selectedKeys={[rootFolder]} mode="inline">
      <Menu.Item key="/">
        <YIcon name="RobotOutlined" style={{ marginRight: 5 }} />
        前端组
      </Menu.Item>
    </Menu>
  );
};

export default Sidebar;
