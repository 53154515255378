/** 本文件由工具自动生成,请勿手动修改！ **/
// @ts-nocheck
import { useState, useEffect } from 'react';
import * as R from 'ramda';
import * as querystring from 'querystring';
import { ParsedUrlQueryInput } from 'querystring';
import Request, { YWRequestConfig } from '@utils/request';
import { getPath, reduceParams } from './utils';

const { basePath, loginPath } = getPath("PROAUTH")


export interface API25815Req {

}

export interface API25815Res {
  /** 用户id  */
  userId?: string;
  /** 用户的钉钉userId  */
  dingdingUserId?: string;
  /** 钉钉用户的头像地址  */
  dingdingHeadIconUrl?: string;
  /** 用户名称  */
  userName?: string;
  /** 用户手机号  */
  userPhone?: string;
  /** 备注  */
  remark?: string;
  /** 邮箱地址  */
  userEmail?: string;
  /** DISABLE NORMAL  */
  status?: string;
  /** 部门id  */
  orgId?: string;
  /** 所属部门名称  */
  orgName?: string;
  /** 所属部门全称  */
  orgFullName?: string;
  
}

// 25815 获取当前用户数据

export const useGetAPI25815 = () => {
  let isMount = true;
  const [isAPI25815Loading, setIsAPI25815Loading] = useState(false);
  const [dataAPI25815, setDataAPI25815] = useState<API25815Res>({} as API25815Res);
  const fetchAPI25815Data = async ( config?: YWRequestConfig): Promise<API25815Res> => {
    
    if ( isMount ) {
      setIsAPI25815Loading(true);
    }

    try {
      
      
      const res: any = await Request.get<API25815Res>(`${basePath}/ywwl-admin/user/current/info`, {
        loginHost: loginPath,
        ...config
      });
      
      
      
      if (isMount) {
        setDataAPI25815(res || {} as API25815Res);
        setIsAPI25815Loading(false);
      }
      return res;
    } catch (error) {
      console.error('API error: ', error);
      if ( isMount ) {
        setIsAPI25815Loading(false);
      }
      return false as unknown as API25815Res;
    }
  }

  useEffect(() => {
    return () => {
      isMount = false;
    };
  }, []);

  return {
    isAPI25815Loading,
    dataAPI25815,
    fetchAPI25815Data,
  }
}

