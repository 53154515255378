export const PARA_INVALID = 17010001;
export const PARA_NULL = 17010002;
export const PARA_TYPE_ERROR = 17010003;
export const PARA_MISS = 17010004;
export const USER_UNLOGIN = 17020001;
export const USER_TOKEN_INVALID = 17020002;
export const USER_TOKEN_NULL = 17020003;
export const USER_ERROE = 17020004;
export const USER_NOT_EXIST = 17020005;
export const USER_INFO_ERROR = 17020006;
export const USER_EXIST = 17020007;
export const USER_SCAN_LOGIN_FAIL = 17020008;
export const USER_RESET_PWD_NEW_NULL_ERROR = 17020009;
export const USER_RESET_PWD_OLD_NULL_ERROR = 17020010;
export const BIZ_ = 17030001;
export const THIRD_SYSTEM_ERROR = 17040001;
export const INTERNAL_SERVICE_ERROR = 17050001;
export const EXTERNAL_SERVICE_ERROR = 17050002;
export const INTERFACE_FORBIDDEN_VISIT = 17060001;
export const INTERFACE_URL_INVALID = 17060003;
export const INTERFACE_REQUEST_TIMEOUT = 17060004;
export const INTERFACE_OVERLOAD = 17060005;
export const NO_PERMISSION = 17070001;

export const TOKEN_FAIL_OLD_1 = -2;
export const TOKEN_FAIL_OLD_2 = 1000;

export const BIZ_MODULE_NOTFOUND = 17031000;

export const GO_TO_LOGIN = [
  USER_UNLOGIN,
  USER_TOKEN_INVALID,
  USER_TOKEN_NULL,
  TOKEN_FAIL_OLD_1,
  TOKEN_FAIL_OLD_2,
];

export const NO_MESSAGE = [BIZ_MODULE_NOTFOUND];
