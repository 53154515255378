import React from 'react';
import { Breadcrumb, Button } from 'antd';
import { useFileData } from '@stores/aliyun';
import { ModalWrapStyle } from './styles';

const MyBreadcrumb: React.FC = () => {
  const { breadcrumbList, jump } = useFileData();

  return (
    <ModalWrapStyle>
      {/* 面包屑 */}
      <Breadcrumb>
        <Breadcrumb.Item>
          <Button
            type="link"
            style={{ padding: 0 }}
            onClick={() => jump('jump', undefined, -1)}
          >
            全部文件
          </Button>
        </Breadcrumb.Item>
        {breadcrumbList.map((name, index) => (
          <Breadcrumb.Item key={name}>
            {index === breadcrumbList.length - 1 ? name : (
              <Button
                type="link"
                style={{ padding: 0 }}
                onClick={() => jump('jump', undefined, index)}
              >
                {name}
              </Button>
            )}
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    </ModalWrapStyle>
  );
};

export default MyBreadcrumb;
