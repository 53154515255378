/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import * as OSS from 'ali-oss';
import { useGetAPIOssInfo } from '@generated/api';
import { useAliyunInfoStore } from './index.store';
import { AliyunInfoActionType } from './index.interface';

export const useClientData = () => {
  const [state, dispatch] = useAliyunInfoStore();
  const { fetchAPIOssInfoData } = useGetAPIOssInfo();
  // 获取oss
  const getSTSToken = async () => {
    const res = await fetchAPIOssInfoData();
    return !res ? {} : {
      accessKeyId: res.accessKeyId,
      accessKeySecret: res.accessKeySecret,
      stsToken: res.securityToken,
    };
  };
  // 获取oss用户
  const getClientInfo = async () => {
    const ossInfo = await getSTSToken();
    const client = new OSS({
      ...ossInfo,
      region: 'oss-cn-hangzhou',
      bucket: 'yw-fed-static',
      refreshSTSToken: getSTSToken, // 刷新临时访问凭证。
    });

    dispatch({
      type: AliyunInfoActionType.INIT_CLIENT,
      payload: {
        data: {
          ...state,
          client,
        },
      },
    });
  };

  return {
    client: state.client,
    getClientInfo,
  };
};
