import React, { Suspense } from 'react';
import { Router } from 'react-router';
import { Layout } from 'antd';
import { createBrowserHistory } from 'history';
import Routes from './router';
import Sidebar from './components/Sidebar';
import Breadcrumb from './components/Breadcrumb';
import Loading from './components/Loading';

const { Header, Sider, Content } = Layout;
const history = createBrowserHistory();

const App: React.FC = () => (
  <Layout>
    <Sider width={256}>
      <Sidebar />
    </Sider>
    <Layout
      style={{
        margin: 20,
        minHeight: 'calc(100vh - 40px)',
      }}
    >
      <Header
        style={{
          padding: 0,
          background: 'transparent',
          height: 'auto',
          lineHeight: 'normal',
        }}
      >
        <Breadcrumb />
      </Header>
      <Content
        style={{
          marginTop: 20,
          background: '#fff',
        }}
      >
        <Suspense fallback={Loading}>
          <Router history={history}>
            <Routes />
          </Router>
        </Suspense>
      </Content>
    </Layout>
  </Layout>
);

export default App;
