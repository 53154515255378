import React, { lazy } from 'react';
import { Route, Switch, Redirect } from 'react-router';

const Home = lazy(() => import('../pages/Home/index'));

const PageRoute = () => {
  React.useEffect(() => {
    window.history.replaceState(
      null,
      '',
      '/home',
    );
  }, []);
  return (
    <div className="main">
      <Route exact path="/" render={() => <Redirect to="/home" />} />
      <Switch>
        <Route path="/home" component={Home} />
        <Route path="*" component={Home} />
      </Switch>
    </div>
  );
};

export default PageRoute;
