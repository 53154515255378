import { useAliyunInfoStore } from './index.store';
import { AliyunInfoActionType } from './index.interface';

export const useRootFolderData = () => {
  const [state, dispatch] = useAliyunInfoStore();
  const setRootFolder = (rootFolder: string) => {
    dispatch({
      type: AliyunInfoActionType.SET_ROOT_FOLDER,
      payload: {
        data: {
          ...state,
          rootFolder,
        },
      },
    });
  };

  return {
    rootFolder: state.rootFolder,
    setRootFolder,
  };
};
