// 获取文件名
export const getFileName = (name: string): string => name.split('/').filter((item) => item).slice(-1)[0];
// 文件尺寸
export const getSize = (size: number): string => {
  const kSize = size / 1024;
  return kSize >= 1024 ? `${(kSize / 1024).toFixed(2)}M` : `${kSize.toFixed(2)}k`;
};
// 文件类型
export const imgTypeList = ['png', 'jpg', 'jpeg', 'webp', 'gif', 'svg', 'ico'];
export const getFileType = (fileUrl: string): string => {
  const url = fileUrl.indexOf('?') > -1
    ? fileUrl.substr(0, fileUrl.indexOf('?'))
    : fileUrl;
  let fileType = (url.split('.').pop() || '').toLocaleLowerCase();
  fileType = !/^\w+$/.test(fileType) ? 'image' : fileType;
  return imgTypeList.indexOf(fileType) > -1 ? 'image' : fileType;
};
