import { useAliyunInfoStore } from './index.store';
import { AliyunInfoActionType } from './index.interface';
import { systemUploadName } from './useFileData';

export const useCheckedData = () => {
  const [state, dispatch] = useAliyunInfoStore();

  // 全不选 =》全选
  const allCheck = () => {
    dispatch({
      type: AliyunInfoActionType.SET_CHECKED,
      payload: {
        data: {
          ...state,
          checked: {
            allChecked: true,
            fileList: state.fileList.map((item) => item.name).filter((item) => item !== systemUploadName),
            folderList: state.folderList.map((item) => item.name),
          },
        },
      },
    });
  };
  // 全选 =》全不选
  const allUncheck = () => {
    dispatch({
      type: AliyunInfoActionType.SET_CHECKED,
      payload: {
        data: {
          ...state,
          checked: {
            allChecked: false,
            fileList: [],
            folderList: [],
          },
        },
      },
    });
  };
  // 单个项目不选 =》选中
  const addCheck = (name: string, type: 'file' | 'folder') => {
    const { fileList, folderList } = state.checked;

    if (type === 'file') {
      fileList.push(name);
    } else {
      folderList.push(name);
    }

    dispatch({
      type: AliyunInfoActionType.SET_CHECKED,
      payload: {
        data: {
          ...state,
          checked: {
            allChecked: folderList.length === state.folderList.length && fileList.length === state.fileList.length - 1,
            fileList,
            folderList,
          },
        },
      },
    });
  };
  // 单个项目选中 =》不选
  const removeCheck = (index: number, type: 'file' | 'folder') => {
    const { fileList, folderList } = state.checked;

    if (type === 'file') {
      fileList.splice(index, 1);
    } else {
      folderList.splice(index, 1);
    }

    dispatch({
      type: AliyunInfoActionType.SET_CHECKED,
      payload: {
        data: {
          ...state,
          checked: {
            allChecked: false,
            fileList,
            folderList,
          },
        },
      },
    });
  };

  return {
    listLoading: state.listLoading,
    checked: state.checked,
    folderList: state.folderList,
    fileList: state.fileList,
    allCheck,
    allUncheck,
    addCheck,
    removeCheck,
  };
};
